import {
  EventType,
  type ExecutionResult,
} from "constants/AppsmithActionConstants/ActionConstants";
import type { SetterConfig, Stylesheet } from "entities/AppTheming";
import memoizeOne from "memoize-one";
import { Elevations } from "modules/ui-builder/ui/wds/constants";
import { ContainerComponent } from "modules/ui-builder/ui/wds/Container";
import React, { type ReactNode } from "react";
import type {
  AnvilConfig,
  AutocompletionDefinitions,
  WidgetBaseConfiguration,
  WidgetDefaultProps,
} from "WidgetProvider/constants";
import type { DerivedPropertiesMap } from "WidgetProvider/factory";
import type { WidgetProps, WidgetState } from "widgets/BaseWidget";
import BaseWidget from "widgets/BaseWidget";
import type { ContainerWidgetProps } from "widgets/ContainerWidget/widget";

import type { ActionData } from "ce/reducers/entityReducers/actionsReducer";
import store from "store";
import { AIChat, type Message } from "../component";
import {
  anvilConfig,
  autocompleteConfig,
  defaultsConfig,
  metaConfig,
  methodsConfig,
  propertyPaneContent,
  propertyPaneStyle,
} from "./config";
import * as MessageMapper from "./mappers/messageMapper";

export interface WDSAIChatWidgetProps
  extends ContainerWidgetProps<WidgetProps> {
  threadId: string;
}

class WDSAIChatWidget extends BaseWidget<WDSAIChatWidgetProps, WidgetState> {
  static type = "WDS_AI_CHAT_WIDGET";

  static getConfig(): WidgetBaseConfiguration {
    return metaConfig;
  }

  static getDefaults(): WidgetDefaultProps {
    return defaultsConfig;
  }

  static getPropertyPaneConfig() {
    return [];
  }
  static getPropertyPaneContentConfig() {
    return propertyPaneContent;
  }

  static getPropertyPaneStyleConfig() {
    return propertyPaneStyle;
  }

  static getMethods() {
    return methodsConfig;
  }

  static getAutocompleteDefinitions(): AutocompletionDefinitions {
    return autocompleteConfig;
  }

  static getSetterConfig(): SetterConfig | null {
    return {
      __setters: {
        setVisibility: {
          path: "isVisible",
          type: "boolean",
        },
      },
    };
  }

  static getDerivedPropertiesMap(): DerivedPropertiesMap {
    return {};
  }

  static getDefaultPropertiesMap(): Record<string, string> {
    return {};
  }

  static getMetaPropertiesMap(): Record<string, unknown> {
    return {
      thread: undefined,
      messages: [],
      prompt: "",
      isWaitingForResponse: false,
      isThreadLoading: false,
    };
  }

  static getAnvilConfig(): AnvilConfig | null {
    return anvilConfig;
  }

  static getStylesheetConfig(): Stylesheet {
    return {};
  }

  componentDidUpdate(prevProps: WDSAIChatWidgetProps): void {
    if (
      !this.props.threadId &&
      !this.props.isThreadLoading &&
      !prevProps.queryData
    ) {
      this.getThreads();
    }
  }

  getThreads = () => {
    this.props.updateWidgetMetaProperty("isThreadLoading", true);

    const params = {
      requestType: "getThreads",
      widgetId: this.props.widgetId,
    };

    this.executeAction({
      triggerPropertyName: "onClick",
      dynamicString: `{{${this.props.queryRun}.run(${JSON.stringify(params)})}}`,
      event: {
        type: EventType.ON_CLICK,
        callback: this.handleGetThreadsComplete,
      },
    });
  };

  getInitialAssistantMessage = memoizeOne(
    (initialAssistantMessage, initialAssistantSuggestions): Message[] => {
      return [
        {
          id: Math.random().toString(),
          content: initialAssistantMessage || "",
          role: "assistant",
          promptSuggestions: initialAssistantSuggestions || [],
          citations: [],
        },
      ];
    },
  );

  handleGetThreadsComplete = (result: ExecutionResult) => {
    if (!result.success || this.props.queryData?.activeThreads.length === 0) {
      this.props.updateWidgetMetaProperty("isThreadLoading", false);

      this.props.updateWidgetMetaProperty(
        "messages",
        this.getInitialAssistantMessage(
          this.props.initialAssistantMessage,
          this.props.initialAssistantSuggestions,
        ),
      );

      return;
    }

    // BE send the latest updated thread at the top of the array
    this.props.updateWidgetMetaProperty(
      "threadId",
      this.props.queryData.activeThreads[0].id,
    );

    this.getMessages();
  };

  getMessages = () => {
    const params = {
      requestType: "getMessages",
      threadId: this.props.threadId,
      widgetId: this.props.widgetId,
    };

    this.executeAction({
      triggerPropertyName: "onClick",
      dynamicString: `{{${this.props.queryRun}.run(${JSON.stringify(params)})}}`,
      event: {
        type: EventType.ON_CLICK,
        callback: () => {
          this.props.updateWidgetMetaProperty("isThreadLoading", false);
          this.props.updateWidgetMetaProperty("messages", [
            ...this.getInitialAssistantMessage(
              this.props.initialAssistantMessage,
              this.props.initialAssistantSuggestions,
            ),
            ...MessageMapper.fromDto(this.props.queryData?.messages.content),
          ]);
        },
      },
    });
  };

  handleMessageSubmit = () => {
    const params = {
      requestType: "sendMessage",
      message: this.props.prompt,
      widgetId: this.props.widgetId,
      threadId: this.props.threadId,
    };

    this.props.updateWidgetMetaProperty("messages", [
      ...this.props.messages,
      {
        createdAt: new Date(),
        id: Math.random().toString(),
        content: this.props.prompt,
        role: "user",
      },
    ]);
    this.props.updateWidgetMetaProperty("isWaitingForResponse", true);
    this.props.updateWidgetMetaProperty("prompt", "");

    this.executeAction({
      triggerPropertyName: "onClick",
      dynamicString: `{{${this.props.queryRun}.run(${JSON.stringify(params)})}}`,
      event: {
        type: EventType.ON_CLICK,
        callback: this.handleSendMessageComplete,
      },
    });
  };

  handleSendMessageComplete = (result: ExecutionResult) => {
    this.props.updateWidgetMetaProperty("isWaitingForResponse", false);

    if (
      result.success ||
      this.props.queryData.messages ||
      this.props.queryData.currentThreadId
    ) {
      this.props.updateWidgetMetaProperty(
        "threadId",
        this.props.queryData.currentThreadId,
      );

      this.props.updateWidgetMetaProperty("messages", [
        ...this.props.messages,
        ...MessageMapper.fromDto([this.props.queryData?.message]),
      ]);
    }
  };

  handlePromptChange = (prompt: string) => {
    this.props.updateWidgetMetaProperty("prompt", prompt);
  };

  handleApplyAssistantSuggestion = (suggestion: string) => {
    this.props.updateWidgetMetaProperty("prompt", suggestion);
  };

  onDeleteThread = () => {
    if (!this.props.threadId) {
      this.props.updateWidgetMetaProperty("messages", [
        ...this.getInitialAssistantMessage(
          this.props.initialAssistantMessage,
          this.props.initialAssistantSuggestions,
        ),
      ]);

      return;
    }

    this.props.updateWidgetMetaProperty("isThreadLoading", true);

    const params = {
      // Instead of deleting the thread, we create a new one so that we can continue using the previous one
      requestType: "createThread",
      widgetId: this.props.widgetId,
    };

    this.executeAction({
      triggerPropertyName: "onClick",
      dynamicString: `{{${this.props.queryRun}.run(${JSON.stringify(params)})}}`,
      event: {
        type: EventType.ON_CLICK,
        callback: () => {
          this.props.updateWidgetMetaProperty("threadId", null);
          this.props.updateWidgetMetaProperty("isThreadLoading", false);
          this.props.updateWidgetMetaProperty("messages", [
            ...this.getInitialAssistantMessage(
              this.props.initialAssistantMessage,
              this.props.initialAssistantSuggestions,
            ),
          ]);
        },
      },
    });
  };

  getDatasourceId(): string {
    const state = store.getState();
    const queryName = this.props.queryRun;

    const query: ActionData = state.entities.actions.find(
      (action: ActionData) => {
        return action.config.name === queryName;
      },
    );

    if (!query) {
      throw new Error("Query not found");
    }

    if (!query.config.datasource.id) {
      throw new Error("Datasource not found");
    }

    return query.config.datasource.id;
  }

  getWidgetView(): ReactNode {
    return (
      <ContainerComponent
        elevatedBackground
        elevation={Elevations.CARD_ELEVATION}
        noPadding
        widgetId={this.props.widgetId}
      >
        <AIChat
          chatDescription={this.props.chatDescription}
          chatTitle={this.props.assistantName}
          datasourceId={this.getDatasourceId()}
          isThreadLoading={this.props.isThreadLoading}
          isWaitingForResponse={this.props.isWaitingForResponse}
          onApplyAssistantSuggestion={this.handleApplyAssistantSuggestion}
          onDeleteThread={this.onDeleteThread}
          onPromptChange={this.handlePromptChange}
          onSubmit={this.handleMessageSubmit}
          prompt={this.props.prompt}
          promptInputPlaceholder={this.props.promptInputPlaceholder}
          size={this.props.chatHeightSize}
          thread={this.props.messages}
          threadId={this.props.threadId}
        />
      </ContainerComponent>
    );
  }
}

export default WDSAIChatWidget;

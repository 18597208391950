import { Markdown, Spinner } from "@appsmith/wds";
import React from "react";
import { useTextFileLoader } from "../useTextFileLoader";
import styles from "./styles.module.css";
import type { MarkdownDocumentViewerProps } from "./types";

export const MarkdownDocumentViewer = ({
  citation,
  ...rest
}: MarkdownDocumentViewerProps) => {
  const contentNodeRef = React.useRef<HTMLDivElement>(null);
  const { content, hasError, isLoading } = useTextFileLoader(citation.url);

  const renderContent = () => {
    if (isLoading) {
      return (
        <div className={styles.spinner}>
          <Spinner />
        </div>
      );
    }

    if (hasError) {
      return <div>{"Can't load the file..."}</div>;
    }

    if (content) {
      return <Markdown ref={contentNodeRef}>{content}</Markdown>;
    }

    return null;
  };

  return (
    <div className={styles.markdownDocumentViewer} {...rest}>
      {renderContent()}
    </div>
  );
};

import { useEffect, useState } from "react";
import type { CitationDetails } from "./types";

import { api } from "api/core";
import PluginsApi from "api/PluginApi";
import type { CitationDetailsDto } from "../widget/dtos/citationDetailsDto";
import * as CitationDetailsMapper from "../widget/mappers/citationDetailsMapper";

interface FetchCitationParams {
  citationId: string;
  datasourceId: string;
  messageId: string;
  threadId: string;
}

const fetchCitation = async ({
  citationId,
  datasourceId,
  messageId,
  threadId,
}: FetchCitationParams): Promise<CitationDetails | null> => {
  const apiUrl = PluginsApi.defaultDynamicTriggerURL(datasourceId);
  const response = await api.post<{ trigger: CitationDetailsDto }>(apiUrl, {
    datasourceId,
    requestType: "GET_RAG_CITATION_FILE",
    displayType: "DROP_DOWN",
    parameters: {
      citationId,
      messageId,
      threadId,
    },
  });

  if (!response.responseMeta.success) {
    return Promise.reject(response.responseMeta.error);
  }

  return CitationDetailsMapper.fromDto(response.data.trigger);
};

interface UseFetchCitationParams {
  citationId?: string;
  datasourceId: string;
  messageId?: string;
  threadId: string;
}

export const useFetchCitation = ({
  citationId,
  datasourceId,
  messageId,
  threadId,
}: UseFetchCitationParams) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [citation, setCitation] = useState<CitationDetails | null>(null);

  useEffect(
    function handle() {
      if (!citationId || !messageId) return;

      setIsLoading(true);
      setHasError(false);
      setCitation(null);

      const fetchData = async () => {
        try {
          const citation = await fetchCitation({
            citationId,
            datasourceId,
            messageId,
            threadId,
          });

          setCitation(citation);
        } catch (error) {
          setHasError(true);
        } finally {
          setIsLoading(false);
        }
      };

      fetchData();
    },
    [citationId, datasourceId, messageId, threadId],
  );

  return { isLoading, hasError, citation };
};

import {
  Flex,
  Sidebar,
  SidebarProvider,
  type SidebarState,
} from "@appsmith/wds";
import React, { useCallback, useState } from "react";

import { useAIChatHeight } from "../hooks/useAIChatHeight";
import { ChatCitationEmpty } from "./ChatCitationEmpty";
import { ChatDocumentViewer } from "./ChatDocumentViewer";
import { ChatHeader } from "./ChatHeader";
import { ChatInputSection } from "./ChatInputSection";
import { ChatMessageList } from "./ChatMessageList";
import styles from "./styles.module.css";
import { type AIChatProps, type MessageCitation } from "./types";

export const AIChat = (props: AIChatProps) => {
  const {
    chatDescription,
    chatTitle,
    datasourceId,
    isThreadLoading,
    isWaitingForResponse = false,
    onApplyAssistantSuggestion,
    onAttachFiles,
    onDeleteThread: onDeleteThreadProp,
    onPromptChange,
    onSubmit,
    prompt,
    promptInputPlaceholder,
    size,
    thread,
    threadId,
  } = props;
  const { search } = window.location;
  const queryParams = new URLSearchParams(search);
  const isEmbed = queryParams.get("embed") === "true";
  const chatHeight = useAIChatHeight(size, isEmbed);
  const isDeleteThreadDisabled =
    thread.length === 1 || isThreadLoading || isWaitingForResponse;
  const [sidebarState, setSidebarState] = useState<SidebarState>("collapsed");
  const [selectedCitation, setSelectedCitation] = useState<
    MessageCitation | undefined
  >(undefined);
  const [citationMessageId, setCitationMessageId] = useState<
    string | undefined
  >(undefined);

  const handleCitationOpen = useCallback(
    (messageId: string, citation: MessageCitation) => {
      setCitationMessageId(messageId);
      setSelectedCitation(citation);
    },
    [],
  );

  // along with deleting the thread, we also reset the selected citation when thread is deleted,
  // this is to ensure that the sidebar is resetted when the thread is deleted
  const onDeleteThread = useCallback(() => {
    onDeleteThreadProp?.();
  }, [onDeleteThreadProp]);

  return (
    <div
      className={styles.aiChat}
      style={
        {
          "--chat-height": chatHeight,
        } as React.CSSProperties
      }
    >
      <ChatHeader
        description={chatDescription}
        isDeleteThreadDisabled={isDeleteThreadDisabled}
        onDeleteThread={onDeleteThread}
        setSidebarState={setSidebarState}
        sidebarState={sidebarState}
        title={chatTitle}
      />
      <SidebarProvider
        className={styles.sidebarWrapper}
        onStateChange={setSidebarState}
        side="end"
        state={sidebarState}
      >
        <Flex direction="column" width="100%">
          <ChatMessageList
            isThreadLoading={isThreadLoading}
            onApplyAssistantSuggestion={onApplyAssistantSuggestion}
            onOpenCitation={handleCitationOpen}
            thread={thread}
          />
          <ChatInputSection
            isThreadLoading={isThreadLoading}
            isWaitingForResponse={isWaitingForResponse}
            onAttachFiles={onAttachFiles}
            onPromptChange={onPromptChange}
            onSubmit={onSubmit}
            prompt={prompt}
            promptInputPlaceholder={promptInputPlaceholder}
          />
        </Flex>
        <Sidebar title={selectedCitation?.name || ""}>
          {({ isAnimating }) => {
            if (!selectedCitation) {
              return <ChatCitationEmpty />;
            }

            if (selectedCitation && citationMessageId) {
              return (
                <ChatDocumentViewer
                  citationId={selectedCitation.id}
                  datasourceId={datasourceId}
                  isAnimating={isAnimating}
                  messageId={citationMessageId}
                  threadId={threadId}
                />
              );
            }

            return null;
          }}
        </Sidebar>
      </SidebarProvider>
    </div>
  );
};

import { Spinner, useSidebar } from "@appsmith/wds";
import React, { useEffect } from "react";
import { MarkdownDocumentViewer } from "./documentViewers/MarkdownDocumentViewer";
import { PdfDocumentViewer } from "./documentViewers/PdfDocumentViewer";
import { TextDocumentViewer } from "./documentViewers/TextDocumentViewer";
import styles from "./styles.module.css";
import type { CitationDetails } from "./types";
import { useFetchCitation } from "./useFetchCitation";

interface ChatDocumentViewerProps {
  citationId: string;
  datasourceId: string;
  messageId: string;
  threadId: string;
  isAnimating: boolean;
}

export const ChatDocumentViewer = ({
  citationId,
  datasourceId,
  isAnimating,
  messageId,
  threadId,
}: ChatDocumentViewerProps) => {
  const citationQuery = useFetchCitation({
    citationId,
    datasourceId,
    messageId,
    threadId,
  });
  const { setState } = useSidebar();

  useEffect(
    function handleNewCitation() {
      if (citationId) {
        setState("expanded");
      }
    },
    [citationId],
  );

  const renderDocumentViewer = (citation: CitationDetails) => {
    switch (citation.type) {
      case "PDF":
        return (
          <PdfDocumentViewer citation={citation} isResizing={isAnimating} />
        );
      case "TEXT":
        return <TextDocumentViewer citation={citation} />;
      case "MD":
        return <MarkdownDocumentViewer citation={citation} />;
      default:
        return null;
    }
  };

  const renderContent = () => {
    if (citationQuery.hasError) {
      return <h1>Error fetching citation</h1>;
    }

    if (!citationQuery.citation) {
      return (
        <div className={styles.documentViewerSpinner}>
          <Spinner />
        </div>
      );
    }

    return renderDocumentViewer(citationQuery.citation);
  };

  return <div className={styles.documentViewer}>{renderContent()}</div>;
};

import { Flex, Markdown, Text } from "@appsmith/wds";
import { AIAvatarCustomIcon } from "appsmith-icons";
import { format } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import React from "react";
import { ChatPromptSuggestions } from "./ChatPromptSuggestions";
import { ChatDocumentCitationItem } from "./citationItems/ChatDocumentCitationItem";
import { ChatNotionPageCitationItem } from "./citationItems/ChatNotionPageCitationItem";
import { ChatWebPageCitationItem } from "./citationItems/ChatWebPageCitationItem";
import styles from "./styles.module.css";
import type { Message, MessageCitation } from "./types";

interface ChatMessageItemProps {
  message: Message;
  isAssistantSuggestionVisible?: boolean;
  onApplyAssistantSuggestion?: (suggestion: string) => void;
  onOpenCitation: (messageId: string, citation: MessageCitation) => void;
}

export const ChatMessageItem = (props: ChatMessageItemProps) => {
  const {
    isAssistantSuggestionVisible,
    message,
    onApplyAssistantSuggestion,
    onOpenCitation,
  } = props;
  const {
    citations = [],
    content,
    createdAt,
    promptSuggestions = [],
    role,
  } = message;

  const getFormattedDate = (createdAt: number | Date) => {
    if (createdAt instanceof Date) return format(createdAt, "HH:mm, dd MMM");

    // Convert to milliseconds for JavaScript Date
    const date = new Date(createdAt * 1000);

    // Get the user's local time zone
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    // Convert the date to the user's time zone
    const zonedDate = toZonedTime(date, timeZone);

    // Format the date in "HH:mm, dd MMM" format
    return format(zonedDate, "HH:mm, dd MMM");
  };

  const handleCitationOpen = (citation: MessageCitation) => {
    onOpenCitation(message.id, citation);
  };

  const assistantMessage = (
    <Flex direction="row" gap="spacing-3" isInner minWidth="spacing-0">
      <AIAvatarCustomIcon
        area-label="Appsmith AI"
        className={styles.avatarImage}
      />
      <Flex direction="column" gap="spacing-4" minWidth="spacing-0">
        {createdAt && <Text size="caption">{getFormattedDate(createdAt)}</Text>}
        <Markdown
          data-markdown=""
          extraComponents={{
            span: ({ node, ...props }) => {
              if (!node?.properties["dataCitationId"]) {
                return <span {...props}>{props.children}</span>;
              }

              const citationId = String(node.properties["dataCitationId"]);
              const citation = citations.find(({ id }) => id === citationId);

              if (!citation) {
                return null;
              }

              if (citation.type === "WEB_SCRAPE") {
                return <ChatWebPageCitationItem citation={citation} />;
              }

              if (citation.type === "NOTION") {
                return <ChatNotionPageCitationItem citation={citation} />;
              }

              return (
                <ChatDocumentCitationItem
                  citation={citation}
                  onOpen={handleCitationOpen}
                />
              );
            },
          }}
        >
          {content}
        </Markdown>
        {isAssistantSuggestionVisible && (
          <ChatPromptSuggestions
            onApplyAssistantSuggestion={onApplyAssistantSuggestion}
            suggestions={promptSuggestions}
          />
        )}
      </Flex>
    </Flex>
  );

  const userMessage = (
    <Flex
      alignItems="end"
      className={styles.userMessage}
      direction="column"
      gap="spacing-3"
      isInner
    >
      {createdAt && <Text size="caption">{getFormattedDate(createdAt)}</Text>}
      <Text wordBreak="break-word">{content}</Text>
    </Flex>
  );

  return (
    <Flex
      className={styles.messageListItem}
      direction={role === "assistant" ? "row" : "row-reverse"}
      key={message.id}
    >
      {role === "assistant" && assistantMessage}
      {role === "user" && userMessage}
    </Flex>
  );
};

import { Flex, Spinner } from "@appsmith/wds";
import React, { useEffect, useRef } from "react";

import { ChatMessageItem } from "./ChatMessageItem";
import styles from "./styles.module.css";
import type { Message, MessageCitation } from "./types";

interface ChatThreadProps {
  isThreadLoading?: boolean;
  onApplyAssistantSuggestion?: (suggestion: string) => void;
  onOpenCitation: (messageId: string, citation: MessageCitation) => void;
  thread: Message[];
}

export const ChatMessageList = (props: ChatThreadProps) => {
  const {
    isThreadLoading,
    onApplyAssistantSuggestion,
    onOpenCitation,
    thread,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(
    function scrollToEndOnNewMessages() {
      if (containerRef.current) {
        const container = containerRef.current;
        const lastChild = container.lastElementChild;

        if (lastChild) {
          const lastChildTop = lastChild.getBoundingClientRect().top;
          const containerGap = getComputedStyle(container).gap;
          const containerTop = container.getBoundingClientRect().top;
          const scrollTop =
            lastChildTop -
            containerTop +
            container.scrollTop -
            parseInt(containerGap.replace("px", ""));

          container.scrollTo({
            top: scrollTop,
            behavior: "smooth",
          });
        }
      }
    },
    [thread],
  );

  if (Boolean(isThreadLoading)) {
    return (
      <Flex
        alignItems="center"
        flexGrow={1}
        isInner
        justifyContent="center"
        padding="spacing-5"
      >
        <Spinner />
      </Flex>
    );
  }

  return (
    <Flex
      className={styles.messageList}
      direction="column"
      flexGrow={1}
      gap="spacing-6"
      isInner
      padding="spacing-4"
      ref={containerRef}
    >
      {thread.map((message: Message) => (
        <ChatMessageItem
          isAssistantSuggestionVisible={thread.length === 1}
          key={message.id}
          message={message}
          onApplyAssistantSuggestion={onApplyAssistantSuggestion}
          onOpenCitation={onOpenCitation}
        />
      ))}
    </Flex>
  );
};
